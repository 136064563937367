
import { Vue, Component, Prop } from 'vue-property-decorator';
import Thumbnail from './Thumbnail.vue';

@Component({
  name: "ClassCarousel",
  components: {
    Thumbnail,
  },
})
export default class extends Vue {
  @Prop() classes?: any[];
  index = 0;
}
