const languages: any = {
    'as': 'INR',
    'as-IN': 'INR',
    'bn': 'INR',
    'bn-IN': 'INR',
    'bo': 'INR',
    'bo-IN': 'INR',
    'brx': 'INR',
    'brx-IN': 'INR',
    'ccp': 'INR',
    'ccp-IN': 'INR',
    'en-IN': 'INR',
    'gu': 'INR',
    'gu-IN': 'INR',
    'hi': 'INR',
    'hi-IN': 'INR',
    'kn': 'INR',
    'kn-IN': 'INR',
    'kok': 'INR',
    'kok-IN': 'INR',
    'ks': 'INR',
    'ks-IN': 'INR',
    'ml': 'INR',
    'ml-IN': 'INR',
    'mr': 'INR',
    'mr-IN': 'INR',
    'ne': 'INR',
    'ne-IN': 'INR',
    'or': 'INR',
    'or-IN': 'INR',
    'ta': 'INR',
    'ta-IN': 'INR',
    'te': 'INR',
    'te-IN': 'INR',
    'ur': 'INR',
    'ur-IN': 'INR',
};

export const getCurrency = (): string => {
    const allowedCurrencies = ['USD', 'INR', 'LKR'];
    const localStorageCurrency = localStorage.getItem('skilltoon-currency');

    if (localStorageCurrency && allowedCurrencies.includes(localStorageCurrency)) return localStorageCurrency;
    if (navigator.language in languages) return languages[navigator.language];

    return 'USD';
};
