
import { Component, Vue, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Auth } from '@/store/modules';

import Thumbnail from "@/components/Thumbnail.vue";
import ClassCarousel from '@/components/ClassCarousel.vue';
import { User } from '@/store/auth';

@Component({
  components: {
    Thumbnail,
    ClassCarousel,
  },
  metaInfo: {
    title: 'Master New Skills with Live Interactive Workshops | SkillToon',
    link: [
      { rel: 'canonical', href: 'https://skilltoon.com/' }
    ],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Enhanced Way of Learning and Teaching Skills | SkillToon' },
      { property: 'og:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { property: 'og:image', content: 'https://skilltoon.com/teachers.webp' },
      { name: 'description', content: 'Dive into our immersive live workshops where every session is a stepping stone towards mastering your skills, connecting with experts, and transforming your passion into expertise. Join SkillToon to start shaping your future today!' },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/' },
      { property: 'twitter:title', content: 'Enhanced Way of Learning and Teaching Skills | SkillToon' },
      { property: 'twitter:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { property: 'twitter:image', content: 'https://skilltoon.com/teachers.webp' },
    ]
  }
})
export default class Home extends Vue {
  classes: any[] = [];
  count = 0;
  error = '';

  @Watch('$route.query.view')
  onViewQueryChanged() {
    const { view } = this.$route.query;

    if (!view || typeof view !== 'string') {
      return;
    }

    if (view === 'teachers') {
      this.$vuetify.goTo('#teachers');
      return;
    }

    if (view === 'students') {
      this.$vuetify.goTo("#students");
      return;
    }
  }

  get loggedIn() {
    return Auth.loggedIn;
  }

  get user() {
    return Auth.user as User;
  }

  async doLogout() {
    return Auth.openLogoutPage();
  }

  mounted() {
    this.getClasses();
    this.onViewQueryChanged();
  }

  async getClasses() {
    try {
      const res = await this.$axios.get('/classes?upcoming=true&popular=true');
      const { count } = res.data;
      const items = res.data.items.filter((course: any) => course.meetings_count === course.upcoming_meetings_count);
      this.count = count;
      this.classes = clone(items);
    } catch (err) {
      this.error = err.message;
    }
  }

  get isTeacher() {
    return this.user.role === 'teacher' || this.user.role === 'admin';
  }

  goForLearners() {
    this.$router.push(this.getPath);
  }

  goForTeacher() {
    this.$router.push(this.getBecomeATeacherPath);
  }

  get getBecomeATeacherPath() {
    if (!this.loggedIn) {
      return '/register';
    }

    if (!this.isTeacher) {
      return '/account';
    }

    if (this.isTeacher) {
      return '/create-class';
    }

    return '';
  }

  get getPath() {
    return this.loggedIn ? '/search' : '/register';
  }
}
