
import { Vue, Component, Prop } from "vue-property-decorator";
import { getCurrency } from "@/misc/getCurrency";

interface CourseMediaI {
  main: boolean;
  url: string;
  type: string;
}

@Component({ name: "ClassThumbnail" })
export default class extends Vue {
  @Prop() course!: any;
  closestDate = "";
  meetingsLeft: number = 0;
  feeDisplayed = this.course.fee;

  mounted() {
    const sortedMeetings = this.course.meetings.sort(
      (a: any, b: any) => new Date(a).valueOf() - new Date(b).valueOf()
    ) as any[];
    const closestDate = sortedMeetings.reduce((agg: string, cur: any) => {
      const aggDate = new Date(agg).valueOf();

      if (aggDate < new Date(cur.date).valueOf()) {
        return aggDate;
      }

      return cur.date;
    }, sortedMeetings[0].date);
    const meetingsLeft = sortedMeetings.filter(
      (meeting: any) => new Date(meeting.date).valueOf() > Date.now()
    );
    this.meetingsLeft = meetingsLeft.length;
    this.closestDate = closestDate;

    const currency = getCurrency();
    const feeDisplayed = this.course.feesWithMaterials.find(
      (fee: any) => currency === fee.currency
    );
    if (feeDisplayed) {
      this.feeDisplayed = `${feeDisplayed.amount} ${feeDisplayed.currency}`;
    }
  }

  get sortedMedia() {
    return this.course.media.sort((mediaA: CourseMediaI) =>
      mediaA.main ? 1 : 0
    );
  }

  goToClass() {
    this.$router.push(`/classes/${this.course.slug}`);
  }
}
